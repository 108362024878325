import "./App.css";
import Background from "./components/Background";
import RandomImage from "./components/RandomImage";

function App() {
  return (
    <>
      <Background />
      <div className="App">
        <header className="App-header">
          <div className="App-logo">
            <img src="/logo192.png" alt="Hakon" />
          </div>
          <h3>...für die wichtigen Antworten im Leben</h3>
        </header>
        <main className="App-page">
          <RandomImage />
        </main>
      </div>
    </>
  );
}

export default App;
