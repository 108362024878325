import { useEffect, useState } from "react";
import "./RandomImage.css";

const keckig = {
  src: "/Hakon-proud-logo.png",
  alt: [
    "ich bin so stolz auf deine Geduld",
    "dein Eifer geht in die Geschichte ein",
    "Ruhm und Ehre ob deiner Beharrlichkeit",
  ],
};

const images = [
  { src: "/2023-01-04-Hakon-strong.png", alt: "Stein!" },
  { src: "/2023-01-04-Hakon-wave.png", alt: "Papier!" },
  { src: "/2023-01-04-Hakon-proud.png", alt: "keine Schere" },
];

const wait = (ms = 1000) => new Promise((r) => setTimeout(() => r(), ms));
const { floor, random } = Math;

const RandomImage = () => {
  const [img, setImg] = useState({
    ...keckig,
    alt: ["Willkommen!", "Hallo!", "Grüße!"],
  });
  const [count, setCount] = useState(1);
  const isLoading = img.alt === "...sinniere";
  const handleClick = async () => {
    setImg((prevImg) => ({ ...prevImg, alt: "...sinniere" }));
    await wait(random() * 1000 + 1000);
    setCount((prevCount) => prevCount + 1);
    setImg((prevImg) => {
      if (count % 10 === 0) return keckig;
      const possibleImages = images.filter(({ src }) => src !== prevImg.src);
      const id = floor(random() * possibleImages.length);
      return possibleImages[id];
    });
  };
  let text, textClass;
  if (Array.isArray(img.alt)) {
    text = img.alt[floor(random() * img.alt.length)];
    textClass = "";
  } else {
    text = img.alt;
    textClass = isLoading ? "" : "RandomImage-text";
  }
  useEffect(() => {
    (async () => {
      await wait(1500);
      setImg((prevImg) => ({ ...prevImg, alt: "...sinniere" }));
      await wait(2000);
      setImg(images[floor(random() * images.length)]);
    })();
  }, []);

  const loadingClass = isLoading ? " RandomImage-loading" : "";
  const reloadClass = count < 5 ? "RandomImage-reload" : "RandomImage-hidden";

  return (
    <div className={"RandomImage"}>
      <button
        className={reloadClass}
        onClick={handleClick}
        disabled={isLoading}
        aria-labelledby="again"
      >
        🔄
        <span id="again" className="sr-only">
          nochmal
        </span>
      </button>
      <input
        type="image"
        src={img.src}
        alt={text}
        // title={img.alt}
        className={"RandomImage-img" + loadingClass}
        onClick={handleClick}
        disabled={isLoading}
      />
      <div className="RandomImage-count">{count}</div>
      <div className={textClass + loadingClass}>{text}</div>
    </div>
  );
};

export default RandomImage;
