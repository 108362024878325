import "./Background.css";
const clx = (...classes) => classes.filter((c) => !!c).join(" ");

const Background = ({ theme = "Background-default", bg, overlay }) => (
  <div className={clx("Background-container", theme)}>
    <div className={clx("Background", bg)}>
      <div className={clx("Background-overlay", overlay)}></div>
    </div>
  </div>
);

export default Background;
